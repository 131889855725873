<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
 Add Image

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addFormvalidate">
     <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
        <b-row>
          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE2.src = Tabimage
                addForm.image=''
                ">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block  mt-1">
                  <b-form-group
                      label=" Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                   <validation-provider
                  #default="{ errors }"
                  name="Tab Image "
                  rules="required"
                >
                    <b-form-file
                      ref="refInputE2"
                      v-model="addForm.image"
                      accept=".jpg, .png, .gif, .jpeg"
                      placeholder="Choose file"
                      @input="inputImageRendererTab"
                    />
                     <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addForm.alt_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            
          </b-col>
          <b-col md="6">
            <b-form-group
              label="In Gallery Order"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Gallery Order"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addForm.order" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group
              label="In Slider Order
"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="In Slider Order"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addForm.slider_order"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
         
        
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>

    </validation-observer>

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import GalleryList from "./GalleryList.vue";
import store from '@/store'
import Vue from "vue";

export default {
  setup() {
 
    // const store = useStore();
  const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
     const Tabimage = ref("media/svg/files/blank-image.svg");
    const categoriesList=ref([])
    const table=ref()
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
  
 store.dispatch('categories/AllCategory')
        .then(response => {
         
          categoriesList.value=response.data.data
        })
    const addForm = reactive({
      image: "",
      alt_image: "",
      order: "",
      slider_order: '',
     
    });
    const form=ref()
  
     const addFormvalidate=ref()

    const save = () => {
      
      console.log(addForm)
      const formData = new FormData();

    
        
         addFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("image", addForm.image);
formData.append("alt_image", addForm.alt_image);
formData.append("order", addForm.order);
formData.append("slider_order", addForm.slider_order);


           store.dispatch('gallery/addGallery',formData)
        .then(response => {
            // table.value.refetchData()
             form.value.reset()
           
                refPreviewE2.value.src ="media/svg/files/blank-image.svg"
           Vue.swal({
                       title: "ImageAdded ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
    
        }})
    };

    return {
    
      addForm,
         refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
   
      addFormvalidate,
      categoriesList,
      
     table,
form,
      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
    BTable,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
  GalleryList,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }
  .mt-50.col-12 {
    margin: 10px 0px;
}
  
  </style>